body{font-family: 'Montserrat', sans-serif;font-size: 18px;font-weight: 400;line-height: 30px;color: #312f2e;padding-top: 145px;}
img{max-width: 100%; height: auto;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{color: #312f2e;}
a{text-decoration: none;}

.default-transition {transition: all 0.3s ease;}
.default-gradient{background: linear-gradient(225deg, #EB5B1B 0%, #C44E14 100%) 0% 0% no-repeat;}
.default-btn {display: inline-block;padding: 9px 28px;border-radius: 28px;color: #fff;font-weight: 400;text-decoration: none;}
.default-btn:hover{background: linear-gradient(125deg, #EB5B1B 0%, #C44E14 100%) 0% 0% no-repeat;}
.default-btn img{margin-left: 12px;}
.hightlight-color{color: #EB5B1B;}

#header-section {padding: 18px 0;position: fixed;top: 0;left: 0;right: 0;z-index: 99;}
#header-section.menu-fixed {padding: 10px 0;background-color: #fff;box-shadow: 0px 3px 5px rgba(0,0,0,0.35);}
#header-section.menu-fixed .navbar-brand img {max-width: 170px;}
.navbar .navbar-nav .nav-item .nav-link {color: #312f2e;margin: 0 25px;font-weight: 400;padding: 0;}
.navbar .navbar-nav .nav-item .nav-link:hover,.navbar .navbar-nav .nav-item .nav-link.active{color: #EB5B1B;}
#header-section .navbar-toggler-icon{background: none;color: #fff;}
#header-section .navbar-toggler-icon svg {width: 30px;height: 30px;}

.header-right{display: flex;gap: 30px;align-items: center;margin-left: 35px;}
.header-icon{width: 45px;height: 45px;line-height: 45px;display: inline-block;border-radius: 50%;text-align: center;}

.home-banner-section {position: relative;}
.home-banner-socialbar{position: absolute;top: 50%;transform: translateY(-50%);left: 50px;z-index: 9;}
.socialbar-wrapper{display: flex;flex-direction: column;width: 36px;align-items: center;gap: 25px;padding-top: 200px;padding-bottom: 200px;position: relative;}
.socialbar-wrapper::after,.socialbar-wrapper::before{position: absolute;content: "";background-color: #EB5B1B;width: 1px;height: 150px;z-index: 11;}
.socialbar-wrapper::before {top: 0;}
.socialbar-wrapper::after {bottom: 0;}


.socialbar-wrapper a:hover .icons8-linkedin.gray-icon .Path_22,
.socialbar-wrapper a:hover .icons8-linkedin.gray-icon .Path_23,
.socialbar-wrapper a:hover .icons8-twitter.gray-icon .Path_27,
.socialbar-wrapper a:hover .icons8-twitter.gray-icon .Path_28,
.socialbar-wrapper a:hover .icons8-twitter.gray-icon .Path_29,
.socialbar-wrapper a:hover .icons8-instagram.gray-icon .Path_14,
.socialbar-wrapper a:hover .icons8-instagram.gray-icon .Path_15,
.socialbar-wrapper a:hover .icons8-instagram.gray-icon .Path_16,
.socialbar-wrapper a:hover .icons8-facebook.gray-icon .Path_4,
.socialbar-wrapper a:hover .icons8-facebook.gray-icon .Path_3{
    stroke: #EB5B1B;
}
.socialbar-wrapper a:hover .icons8-linkedin.gray-icon .Ellipse_5,
.socialbar-wrapper a:hover .icons8-linkedin.gray-icon .Path_24,
.socialbar-wrapper a:hover .icons8-linkedin.gray-icon .Path_25,
.socialbar-wrapper a:hover .icons8-twitter.gray-icon .Path_26,
.socialbar-wrapper a:hover .icons8-instagram.gray-icon .Path_17,
.socialbar-wrapper a:hover .icons8-facebook.gray-icon .Path_5 {
    fill: #EB5B1B;
}

.footer-social a:hover .icons8-linkedin.orange-icon .Path_22,
.footer-social a:hover .icons8-linkedin.orange-icon .Path_23,
.footer-social a:hover .icons8-twitter.orange-icon .Path_27,
.footer-social a:hover .icons8-twitter.orange-icon .Path_28,
.footer-social a:hover .icons8-twitter.orange-icon .Path_29,
.footer-social a:hover .icons8-instagram.orange-icon .Path_14,
.footer-social a:hover .icons8-instagram.orange-icon .Path_15,
.footer-social a:hover .icons8-instagram.orange-icon .Path_16,
.footer-social a:hover .icons8-facebook.orange-icon .Path_4,
.footer-social a:hover .icons8-facebook.orange-icon .Path_3{
    stroke: #fff;
}
.footer-social a:hover .icons8-linkedin.orange-icon .Ellipse_5,
.footer-social a:hover .icons8-linkedin.orange-icon .Path_24,
.footer-social a:hover .icons8-linkedin.orange-icon .Path_25,
.footer-social a:hover .icons8-twitter.orange-icon .Path_26,
.footer-social a:hover .icons8-instagram.orange-icon .Path_17,
.footer-social a:hover .icons8-facebook.orange-icon .Path_5 {
    fill: #fff;
}



.home-banner-section .home-banner-row{display: grid;align-items: center;margin: 0;grid-template-columns: repeat(2,1fr);height: calc(100vh - 145px);overflow: hidden;}
.home-banner-image{text-align: right;}
.home-banner-content {padding-left: 16vw;padding-right: 5vw;position: absolute;top: 50%;transform: translateY(-50%);width: 50%;}
.home-banner-content h1{font-size: 83px;font-weight: 800;line-height: 80px;color: #EB5B1B;margin-bottom: 0;}
.home-banner-subheading{color: #312f2e;font-size: 22px;line-height: 36px;font-weight: 500;margin-top: 30px;}
.home-banner-content .default-btn{margin-top:15px;}
#gotonext {width: 75px;height: 75px;line-height: 75px;text-align: center;border-radius: 100%;border: 1px solid #EB5B1B;display: inline-block;position: absolute;bottom: 30px;right: 50%;}
#gotonext img {width: 15px;}


.intro-section{padding: 150px 0 200px;position: relative;overflow: hidden;background-color: #FFFDFC;}
.intro-section::before{
    position: absolute;
    content: "";
    background: url("../images/bg-shape.svg") no-repeat;
    background-size: cover;
    background-position: center right;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    z-index: 0;
    pointer-events: none;
}
.intro-section .row {display: flex;	align-items: center;}
.intro-section h2 {font-size: 44px; font-weight: 400; line-height: 56px;margin-bottom: 30px;}
.about-img_one {position: relative;margin-left: 15px;}
.about-img_one img{position: relative;z-index: 2;}
.about-img_one::before {
	position: absolute;
	content: "";
	background: url("../images/about-grid.svg") no-repeat;
	width: 137px;
	height: 138px;
	bottom: -60px;
	left: -60px;
}
.about-img_one::after {
	position: absolute;
	content: "";
	background-color: #EB5B1B;
	width: 13px;
	height: 160px;
	top: 0;
	margin-left: 30px;
}
.about-img_two {position: absolute;top: 40%;right: 0;text-align: right;}

.cta-section {
    background: url("../images/cta-bg.jpg") no-repeat center center;
    background-size: cover;
    position: relative;   
    overflow: hidden;
}
.cta-section::before{
    position: absolute;
    content: "";
    background-color: rgba(0,0,0,0.75);
    inset: 0;
    z-index: 0;
}
.section-title{
    position: relative;
    padding: 0 80px;
    display: inline-block;
}
.section-title::after,
.section-title::before{
    position: absolute;
    content: "";
    width: 66px;
    height: 2px;
    background-color: #AEACAB;
    top: 50%;
    transform: translateY(-50%);
}
.section-title::before{left: 0}
.section-title::after{right: 0}

.section-title h3 {
    color: #AEACAB;
    font-size: 20px;
    line-height: 56px;
    position: relative;
    padding: 0 25px;
    display: inline-block;
    margin: 0;
}
.section-title h3::after,
.section-title h3::before{
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: #EB5B1B;
    top: 50%;
    transform: translateY(-50%);
}
.section-title h3::before{left: 0}
.section-title h3::after{right: 0}
.cta-left{padding-right: 30px;position: relative;z-index: 1;}
.cta-left .section-title{margin-bottom: 20px;}
.cta-left h4 {color: #FFF6F2;font-size: 44px;font-weight: 600;line-height: 64px;margin-bottom: 60px;padding-right: 50px;}
.cta-section .container {position: relative; padding: 195px 0;}
.cta-section .container::before {
    position: absolute;
    content: "";
    background: url("../images/line.png") no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 9;
    right: 18%;
}
.cta-section::after {
	position: absolute;
	content: "";
	top: -100px;
	bottom: -100px;
	border: 2px solid #EB5B1B;
	border-radius: 50%;
	height: 50vw;
	width: 50vw;
	right: -210px;
	left: auto;
    display: none;
}
.cta-right {
	position: relative;
	justify-content: flex-end;
	display: flex;
	align-items: center;
}
.cta-right h3 {font-size:50px;font-weight: 600;line-height: 120px;color: #FFF6F2;text-transform: capitalize;display: inline-block;position: relative;z-index: 1;}
.cta-right h3::after{
    position: absolute;
    content: "";
    bottom: 10px;
    border-bottom: 20px solid #EB5B1B;
    left: 0;
    right: 0;
    z-index: -1;
}
.cta-right::before {
	position: absolute;
	content: "";
	background: url("../images/icon-cta.svg") no-repeat;
	width: 100px;
	height: 106px;
	bottom: -50px;
	left: 70px;
}

.what-we-do-section{background-color: #261B15;padding: 90px 0 130px;text-align: center; overflow: hidden;}
.section-header{color: #FFF6F2;font-size: 54px;font-weight: 600;}
.what-we-do-section .section-header{margin-bottom: 80px;}
.whatwedo-carousel {margin-right: -150px;}
.whatwedo-block{display: grid;grid-template-columns: repeat(3,1fr);grid-gap: 30px;padding: 0 15px;margin-bottom: 70px;}
.whatwedo-box h3{color: #FFF6F2;font-size: 24px;font-weight: 600;margin-top: 30px;margin-bottom: 0;}
.whatwedo-img{position: relative;display: block;}
.whatwedo-img::before{position: absolute;content: "";inset: 0;background-color: rgba(235, 91, 27, 0.30);transition: all 0.3s ease;}
.whatwedo-box:hover .whatwedo-img::before{background-color: rgba(235, 91, 27, 0.50);}

.what-we-do-section.alt-servicepage {background-color: #fff;padding-top: 30px;}
.alt-servicepage .section-title{color: #AEACAB;}
.alt-servicepage .section-header{color: #312f2e;margin-bottom: 50px;}
.section-description {max-width: 72%;margin: 0 auto 60px;}
.alt-servicepage .whatwedo-block {display: flex;flex-wrap: wrap;justify-content: center;gap: 30px;padding: 0;}
.alt-servicepage .whatwedo-block .whatwedo-box{flex: 0 0 31%;}
.alt-servicepage .whatwedo-box h3{color: #EB5B1B;margin-top: 25px;}

.whatwedo-services{display: grid;grid-template-columns: repeat(3,1fr);grid-gap: 25px;margin: 100px 0 0px; }
.whatwedo-services p:hover{
 background-color: #fe9e740d;
}
.whatwedo-services p{    border: 1px solid #EB5B1B; padding: 25px; position: relative;transition: all 0.2s ease-in-out; height:250px;}
.whatwedo-services p::before{position: absolute;content: "";inset: 0;opacity: 0.1;background-position: center center;background-size: contain;top: 30px;bottom: 30px;}
/*.whatwedo-servicebox-one::before{background: url("../images/icon-gallery.svg") no-repeat;}
.whatwedo-servicebox-two::before{background: url("../images/icon-software.svg") no-repeat;}
.whatwedo-servicebox-three::before{background: url("../images/icon-information.svg") no-repeat;}*/
.whatwedo-services span{display: block;font-size: 24px;font-weight: 600;line-height: 34px;color: #312f2e;padding: 0 20px;margin-top: 30px;}
.whatwedo-services img{ height: 100px; }

.testimonial-section {padding: 90px 0 0px;position: relative;overflow: hidden;}
.testimonial-section::before{position: absolute;content: "";background: url("../images/icon-testimonail-bg.svg");background-size: cover;
    top: 0;bottom: 0;width: 500px;left: 0;right: auto;pointer-events: none;}
.testimonial-section .section-header{color:#312f2e;margin-bottom: 70px;}
.testimonial-carousel .testimonial-box img{width: auto;margin: 0 auto;}
.testimonial-carousel .testimonial-thumb {transform: scale(0.60);border: 3px solid transparent;border-radius: 100%;opacity: 0.5;}
.testimonial-carousel .testimonial-content{display: none;}
.testimonial-carousel .active.center .testimonial-content{display: block;}
.testimonial-carousel .active.center .testimonial-thumb{transform: scale(1);border-color: #EB5B1B;opacity: 1;}
.testimonial-carousel .testimonial-content p {color: #312f2e;font-weight: 300;margin-bottom: 40px;}
.testimonial-carousel .testimonial-content h3{color: #EB5B1B; font-size: 25px;font-weight: 600;margin-top: 20px;margin-bottom: 15px;}
.testimonial-carousel .testimonial-content h5 {font-weight: 600;font-size: 18px;color: #AEACAB;}
.testimonial-section {text-align: center;}
.testimonial-section .active.center .testimonial-content {width: 49vw;position: absolute;top: 280px;margin: 0 auto;left: -18vw;}
.testimonial-carousel .owl-stage-outer {height: 650px;}


.service-single-section .container{display: grid;grid-template-columns: 75% 25%;grid-gap: 50px;justify-content: space-around;}
.service-details-listing{padding: 8px 25px;border: 1px solid #EB5B1B; border-radius: 14px;}
.service-details-listing a{display: block;color: #312f2e;border-bottom: 1px solid #D6D6D6;padding-bottom: 10px;padding-top: 10px;margin: 5px 0;position: relative;transition: all 0.3s ease;}
.service-details-listing a:last-child{border-bottom: none;}
.service-details-listing a::before{position: absolute;content: "";background: url("../images/icon-arrow-left-circle.svg") no-repeat;background-size: contain;right: 0;top: 50%;transform: translateY(-50%);width: 24px;height: 24px;}
.service-details-listing a:hover{color: #EB5B1B;}
.service-single-contentblock{display: grid; grid-template-columns: 45% 55%;grid-gap: 50px;word-wrap: break-word;padding-right: 50px;margin-bottom: 65px;}
.service-single-contentbox h3{font-size: 40px;line-height: 60px;font-weight: 600;margin: 10px 0 25px 0;}
.service-single-contentbox .default-btn{margin-top: 30px;}

.fulwidth.service-single-contentblock{display: block; padding: 0 40px;}
.alt-contentblock .service-single-contentbox h3,.fulwidth .service-single-contentbox h3{font-size: 30px;line-height: 44px;}
.alt-contentblock.service-single-contentblock {grid-template-columns: 55% 45%;margin-bottom: 50px;}
.alt-contentblock.service-single-contentblock .service-single-imgbox{order: 2;}

.services-single-listing{padding-left: 0;}
.services-single-listing li{list-style: none;padding-left: 46px;position: relative;margin-bottom: 20px;}
.services-single-listing li::before{position: absolute;content: "";background: url("../images/icon-check-all.svg") no-repeat;
width: 27px;height: 15px;left: 0;top: 8px;}

.service-single-section .container,.contactus-section{padding: 80px 15px;}
.contactus-section .container{display: grid;grid-template-columns: repeat(2,1fr);grid-gap: 30px;}
.contact-form-block .section-header{color: #312f2e;font-size: 38px;line-height: 50px;}
.contact-form-block p{font-size: 18px; line-height: 28px;font-weight: 500;margin-bottom: 30px;}
.contact-form-block form .form-label{font-size: 18px;font-weight: 500;}
.contact-form-block form .form-control{border-radius: 0;padding: 10px 15px;}
.contact-form-block form .form-control:focus{box-shadow: none;border-color: #000;}
.contact-form-block .default-btn{border: none;margin-top: 15px;padding: 12px 45px;}
.contact-form-block {margin-bottom: 48px;}


.footer-section{padding: 80px 0 30px;background-color: #141414;word-wrap: break-word;}
.footer-logo{margin-bottom: 50px;display: block;}
.footer-box h3 {color: #EB5B1B;font-size:18px; font-weight: 600;margin-bottom: 20px;}
.footer-box p a,.footer-box p {color: #FFF6F2;text-decoration: none;transition: all 0.2s ease-in-out;}
.footer-social a{transition: all 0.2s ease-in-out;}
.footer-social{display: flex;gap: 25px;}
.footer-box p a:hover{color: #EB5B1B;}
.footer-box p >img{margin-right: 10px;}
.footer-box h5 {color:#aaa; font-size: 18px;}
.flist-box{margin-top: 85px;}
.flist-box p img{float: left;}
.flist-details p{position: relative;padding-left: 35px;}
.flist-details p img{position: absolute;left: 0;top: 9px;}
.fshort-intro{max-width: 410px;margin-bottom: 40px;}
.copyright-text{color: #FFF6F2; font-weight: 500;font-size: 15px;margin-bottom: 0;padding-top: 20px; margin-top: 90px;border-top: 1px solid #707070;}
.google-map-block iframe{width: 100%; height: 630px;}

.footer-social a .gray-icon path,
.footer-social a .orange-icon path {
    transition: all 0.2s ease-in-out;
}
.icons8-facebook.orange-icon .Path_3{stroke: #EB5B1B; }
.icons8-facebook.orange-icon .Path_4{stroke: #EB5B1B;}
.icons8-facebook.orange-icon .Path_5{fill: #EB5B1B;}

.icons8-instagram.orange-icon .Path_14 {stroke: #EB5B1B;}
.icons8-instagram.orange-icon .Path_15 {stroke: #EB5B1B;}
.icons8-instagram.orange-icon .Path_16 {stroke: #EB5B1B;}
.icons8-instagram.orange-icon .Path_17 {fill: #EB5B1B;}

.icons8-twitter.orange-icon .Path_26{fill: #EB5B1B; }
.icons8-twitter.orange-icon .Path_27{stroke: #EB5B1B;}
.icons8-twitter.orange-icon .Path_28{stroke: #EB5B1B;}
.icons8-twitter.orange-icon .Path_29{stroke: #EB5B1B;}

.icons8-linkedin.orange-icon .Path_22{stroke: #EB5B1B;}
.icons8-linkedin.orange-icon .Path_23{stroke: #EB5B1B;}
.icons8-linkedin.orange-icon .Path_24{fill: #EB5B1B;}
.icons8-linkedin.orange-icon .Path_25{fill: #EB5B1B;}
.icons8-linkedin.orange-icon .Ellipse_5{fill: #EB5B1B;}



.icons8-facebook.gray-icon .Path_3{stroke: #919191;}
.icons8-facebook.gray-icon .Path_4{stroke: #919191;}
.icons8-facebook.gray-icon .Path_5{fill: #919191;}

.icons8-instagram.gray-icon .Path_14 {stroke: #919191;}
.icons8-instagram.gray-icon .Path_15 {stroke: #919191;}
.icons8-instagram.gray-icon .Path_16 {stroke: #919191;}
.icons8-instagram.gray-icon .Path_17 {fill: #919191;}

.icons8-twitter.gray-icon .Path_26{fill: #919191; }
.icons8-twitter.gray-icon .Path_27{stroke: #919191;}
.icons8-twitter.gray-icon .Path_28{stroke: #919191;}
.icons8-twitter.gray-icon .Path_29{stroke: #919191;}

.icons8-linkedin.gray-icon .Path_22{stroke: #919191;}
.icons8-linkedin.gray-icon .Path_23{stroke: #919191;}
.icons8-linkedin.gray-icon .Path_24{fill: #919191;}
.icons8-linkedin.gray-icon .Path_25{fill: #919191;}
.icons8-linkedin.gray-icon .Ellipse_5{fill: #919191;}