/* ## Responsive */
@media(max-width: 1440px ){

    .home-banner-content{padding-left: 10vw;padding-right: 5vw;}
    .home-banner-content h1{font-size: 60px;line-height: 70px;}
    #gotonext{width: 60px;height: 60px;line-height: 60px;right: 55%;}
    .intro-section h2{font-size: 32px;line-height: 42px;}

}

@media(max-width: 1399px ){

    .testimonial-section .active.center .testimonial-content{left: -16vw;}

    .cta-right h3{font-size: 40px;line-height: 90px;}
    .cta-left h4{font-size: 36px;line-height: 54px;}
    .cta-section .container{padding: 120px 15px;}
}

@media(max-width: 1199px ){

    body{padding-top: 97px;}
    .navbar-brand img {max-width: 150px;}
    .navbar .navbar-nav .nav-item .nav-link{margin: 0 12px; font-size: 16px;}
    .header-right{margin-left: 25px;gap: 15px;}
    #header-section.menu-fixed .navbar-brand img {max-width: 130px;}
    #header-section .navbar-toggler{box-shadow: none !important;}

    .home-banner-socialbar{left: 25px;}
    .home-banner-section .home-banner-row{height: auto;grid-template-columns: 60% 40%;}
    
    .home-banner-content {padding-left: 70px;padding-right: 0vw;}
    .home-banner-content h1 {font-size: 45px;line-height: 50px;}
    #gotonext{right: 50%;}

    .about-img_one img{max-width: 80%;}
    .about-img_two img{max-width: 65%;}
    .about-img_one::after {height: 100px;width: 7px;}
    .about-img_one::before {width: 90px;height: 95px;bottom: -40px;left: -40px;}

    .cta-right{justify-content: center;}
    .cta-right h3{font-size: 30px;line-height: 75px;}
    .cta-right h3::after {border-bottom: 10px solid #EB5B1B;}
    .cta-right::before {background-size: 70%;}
    .cta-section .container::before {right: 12%;}
    .cta-left h4{font-size: 36px;line-height: 50px;margin-bottom: 30px;}
    .what-we-do-section{padding: 50px 0;}
    .contact-form-block .section-header,
    .section-header{font-size: 38px;}
    .what-we-do-section .section-header {margin-bottom: 50px;}
    .whatwedo-services span,.whatwedo-box h3{font-size: 18px;line-height: 26px;margin-top: 15px;}

    .testimonial-section .active.center .testimonial-content{width: 90vw; top: 220px;left:-36vw; padding: 0 60px;}

    .service-single-section .container{grid-template-columns: 70% 30%;padding: 0 30px;}
    .service-single-contentblock{grid-gap: 30px;padding-right: 30px;}
    .alt-contentblock .service-single-contentbox h3, .fulwidth .service-single-contentbox h3,
    .service-single-contentbox h3 {font-size: 28px;line-height: 34px;margin: 10px 0 10px 0px;}
    .service-single-contentbox .default-btn {margin-top: 15px;}
    .service-single-contentblock{margin-bottom: 25px;}
    .fulwidth.service-single-contentblock {padding: 0 15px;}
    .contact-form-block p{font-size: 16px;line-height: 28px;}

}


@media(max-width: 991px ){

    .container{max-width: 100%;padding-left: 15px;padding-right: 15px;}
    .header-right{display: none;}

    #header-section .navbar-toggler {background: linear-gradient(225deg, #EB5B1B 0%, #C44E14 100%) 0% 0% no-repeat;font-size: 20px;padding: 5px 10px;    }
    #sitemenu{background: linear-gradient(225deg, #EB5B1B 0%, #C44E14 100%) 0% 0% no-repeat; border-radius: 0 0 5px 5px;}
    #sitemenu .navbar-nav{padding: 10px 0;}
    .navbar .navbar-nav .nav-item {padding: 5px 15px;}
    .navbar .navbar-nav .nav-item .nav-link{color: #fff;padding: 0;margin: 0;}
    .navbar .navbar-nav .nav-item .nav-link.active,.navbar .navbar-nav .nav-item .nav-link:hover{color: #fff;}

    .home-banner-section .home-banner-row{grid-template-columns: repeat(1,1fr);overflow: visible;}
    .home-banner-content{position: relative;top: 0;transform: none;width: 100%;}
    .home-banner-content h1 {font-size: 36px;line-height: 46px;}
    .home-banner-socialbar{top: 0;transform: none;}
    .home-banner-row .container{ margin-bottom: 50px;}
    .home-banner-image img{width: 100%;padding-left: 90px;}
    #gotonext{display: none;}
    .home-banner-subheading{font-size: 20px;line-height: 30px;margin-top: 15px;margin-bottom: 10px;}
    .socialbar-wrapper{padding-top: 120px;padding-bottom: 120px ;}
    .socialbar-wrapper::after, .socialbar-wrapper::before {height: 100px;}

    .intro-section{padding: 70px 0 120px;}
    .intro-section h2 {font-size: 22px;line-height: 32px;}

    .cta-right h3{font-size: 22px;line-height: 55px;}
    .cta-right h3::after {border-bottom: 8px solid #EB5B1B;bottom: 5px;}
    .fshort-intro{max-width: 100%;}

    .what-we-do-section{padding: 40px 0;}
    .section-header{font-size: 34px;}
    .what-we-do-section .section-header {margin-bottom: 30px;}
    .whatwedo-services span,.whatwedo-box h3{font-size: 16px;line-height: 24px;margin-top: 10px;}
    .what-we-do-section .section-title{margin-bottom: 0;}

    .testimonial-section .active.center .testimonial-content{width: 100vw; left:-34vw;}
    .testimonial-carousel .active.center .testimonial-thumb{transform: scale(0.7);}
    .testimonial-carousel .testimonial-thumb{transform: scale(0.5);}
    .testimonial-carousel .owl-stage-outer {height: 570px;}

    .section-description{max-width: 100%;}
    .alt-servicepage .whatwedo-block .whatwedo-box {flex: 0 0 30%;}
    .whatwedo-services p{padding: 50px;}
    .whatwedo-services p::before {top: 20px;bottom: 20px;}
    .whatwedo-services{margin: 50px 0 30px;}

    .alt-contentblock.service-single-contentblock,
    .service-single-contentblock{grid-template-columns: repeat(1,1fr);padding-right: 30px;}
    .service-single-section .container{grid-gap: 0;}
    .service-details-listing {padding: 10px 20px;border-radius: 16px;}
    .service-details-listing a{margin: 0;padding: 10px 0;font-size: 15px;}
    .alt-contentblock.service-single-contentblock .service-single-imgbox {order: 1;}
    .alt-contentblock.service-single-contentblock .service-single-contentbox{order: 2;}

    .contactus-section .container{grid-template-columns: repeat(1,1fr);}
    .service-single-section .container, .contactus-section {padding: 40px 15px;}
    .contactus-section .section-title{margin-bottom: 0;}

}

@media(max-width: 767px ){

    body{font-size: 15px;line-height: 24px;}

    .intro-section {padding: 60px 0 50px;}
    .intro-section .about-img{ order: 1;margin-bottom: 50px;}
    .intro-section .about-content{ order: 2;}
    .about-img_one img{max-width: 72vw;}
    .about-img_two img{max-width: 62vw;}
    .about-img_one::after{margin-left: 15px;height: 70px;}
    .intro-section .about-content {margin-top: 18vw;}
    .intro-section h2 {font-size: 18px;line-height: 30px;}
    .cta-section {padding: 40px 0 80px;}
    .cta-section .container{padding-top: 0;padding-bottom: 0;}
    .cta-left, .cta-left h4{text-align: center; padding-right:10px;}
    .cta-right{margin-top: 50px;}
    .cta-right::before {background-size: 50%;right: 0;margin: 0 auto;left: -150px;bottom: -90px;}
    .cta-section .container::before{display: none;}
    .section-title{margin-bottom: 20px;padding: 0 50px;}
    .section-title h3{font-size: 18px; line-height: 22px}
    .section-title h3::after, .section-title h3::before{width: 8px;height: 8px;}
    .section-title::after, .section-title::before{width: 40px;}
    .cta-left h4{font-size: 20px;line-height: 36px; padding-right:0px;}
    .footer-social{margin-top: 15px;}
    .contact-form-block .section-header, .section-header{font-size: 32px;line-height: 36px;}
    .google-map-block iframe {height: 70vw;}
    .contact-form-block p {font-size: 14px;line-height: 25px;}

    .whatwedo-carousel {margin-right: 0;}
    .what-we-do-section {padding: 30px 0;}
    .footer-section{padding-top: 30px;}
    .copyright-text{margin-top: 50px;}
    .footer-logo{margin-bottom: 20px;}

    .testimonial-section .section-header{margin-bottom: 0;}
    .alt-servicepage .whatwedo-block .whatwedo-box {flex: 0 0 45%;}
    .service-single-contentbox p {font-size: 15px;}
    .service-single-section .container{grid-template-columns: repeat(1,1fr);margin-bottom: 60px;}
    .alt-contentblock.service-single-contentblock, .service-single-contentblock{padding-right: 0;}
    .service-single-section .container, .contactus-section{padding-top: 20px;}

}

@media(max-width: 575px ){

    .section-title h3{font-size: 15px;padding: 0 15px;}
    .section-title h3::after, .section-title h3::before{width: 5px;height: 5px;}
    .section-title::after, .section-title::before{width: 30px;}
    .flist-box{margin-top: 40px;}
    .footer-box h3{margin-bottom: 10px;}
    .footer-box p{margin-bottom: 10px;}
    .flist-details p{padding-left: 25px;}
    .footer-box p br{display: none;}
    .flist-details p img{max-width: 15px;top: 3px;}
    .footer-box p:last-child{margin-bottom: 0;}
    .whatwedo-block{grid-template-columns: repeat(1,1fr);margin-bottom: 40px;}
    .whatwedo-carousel {padding: 0 15px;}
    .copyright-text{margin-top: 30px;}

    .testimonial-section{padding: 40px 0 20px;}
    .testimonial-section .active.center .testimonial-content{left: 0;position: relative;top: 0;padding: 0 15px;width: 100%;}
    .testimonial-section::before {width: 100vw;opacity: 0.5;}
    .testimonial-carousel .owl-stage-outer {height: auto;}
    .testimonial-carousel .testimonial-content p{margin-bottom: 25px;}
    .testimonial-carousel .testimonial-content h3{font-size: 20px;margin-top: 10px;margin-bottom: 10px;}
    .whatwedo-services{grid-template-columns: repeat(1,1fr);}
    .alt-servicepage .whatwedo-block .whatwedo-box {flex: 0 0 100%;}
    .alt-contentblock .service-single-contentbox h3,.fulwidth .service-single-contentbox h3,.service-single-contentbox h3 {font-size: 24px;line-height: 30px;}
    .fulwidth.service-single-contentblock {padding: 0;}

}